<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">会议手册管理</h2>

        <div class="topBtn">
          <el-dropdown @command="handleCommand" trigger="click">
            <el-button :disabled="haveAuth" type="primary" icon="el-icon-plus">新建</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="w"><use xlink:href="#icon-word1"/><span class="iconfont icon-wenziwendang"></span>文字文档</el-dropdown-item>
              <el-dropdown-item command="s"><use xlink:href="#icon-excel1"/><span class="iconfont icon-a-36-biaogewendang"></span>表格文档</el-dropdown-item>
              <el-dropdown-item command="p"><use xlink:href="#icon-ppt4"/><span class="iconfont icon-yanshiwendang-ppt_pptx"></span>演示文档</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-upload
              ref="upload"
              class="upload-demo"
              action
              :auto-upload="false"
              accept=".xlsx,.docx,.pptx,.doc,.xls,.ppt"
              :limit=1
              :disabled="haveAuth"
              :before-upload="beforeUpload"
              :on-change="handleChange"
              :on-error="handleError"
              :file-list="file"
          >
            <el-button icon="el-icon-upload" type="primary">点击上传</el-button>
          </el-upload>

        </div>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="文件名" align="center">
          </el-table-column>
          <el-table-column prop="version" label="版本号" align="center">
          </el-table-column>
          <el-table-column prop="size" label="文件大小M" align="center" width="130">
            <template style="display: flex" slot-scope="scope">
              <div v-if="scope.row.size">{{(scope.row.size/1024).toFixed(2)}}</div>
              <div v-else>0</div>
            </template>
          </el-table-column>
          <el-table-column prop="createName" label="创建者" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center">
          </el-table-column>
          <el-table-column prop="updateName" label="修改者" align="center">
          </el-table-column>
          <el-table-column prop="modifyTime" label="修改时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template style="display: flex" slot-scope="scope">
              <el-button size="mini" type="primary" @click="open(scope.row)">编辑/预览</el-button>
              <el-button size="mini" type="danger" :disabled="haveAuth" @click="deletFil(scope.row)">删除</el-button>
              <el-button size="mini" type="primary" :disabled="haveAuth" @click="downFile(scope.row)">下载</el-button>
              <el-button size="mini" type="primary" :disabled="haveAuth" @click="shareClick(scope.row)">分享</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>

      <el-dialog title="分享链接" :visible.sync="sharedialogVisible" width="450px" :before-close="sharedialogVisibleClose">
        <div>
          链接权限：
          <el-radio-group v-model="sharLink"  @change="handleLinkType">
            <el-radio :label="1">只读</el-radio>
            <el-radio :label="2">可编辑</el-radio>
          </el-radio-group>
          <div class="sharSty">
            <div>分享链接:</div>
             <div class="copyStye">
               <el-input id="put" v-model="sharVal"></el-input>
               <el-button type="primary" @click="copyLink">复制链接</el-button>
             </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="新建文档" :visible.sync="newMeetdialogVisible" width="77%" top="1vh" :before-close="newMeetdialogVisibleClose">
        <div>
          <div>
            <div class="bigBor" @click="blanTem">
              <div class="frame">+</div>
              <div class="fraText">{{ commName }}</div>
            </div>
          </div>
          <div style="width: 100%;height: 10px;border-top: 1.5px solid #8f8f94;margin: 0 auto;margin-top: 30px"></div>
          <div class="mobanDiv">模板</div>
          <div class="bomImag">
            <div class="bigTem" @click="temClick(item.id)" v-for="(item,index) in meetRecore" :key="index">
              <div class="temImag">
                <el-image :src="require('@/assets/'+command+'.jpg')"></el-image>
              </div>
              <div class="temText">{{item.name}}</div>
            </div>
          </div>
        </div>
      </el-dialog>


    </el-container>
  </el-container>
</template>

<script>
import _qs from "qs";
import Vue from "vue";
export default {
  data() {
    return {
      commName:'',
      newMeetdialogVisible:false,
      sharVal:'',
      sharLink:1,
      sharedialogVisible:false,
      file:[],
      reayName: "",
      // 学组管理的分页
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      taskId:"",
      command:"w",
      sharRow:[],
      haveAuth:false,
      meetRecore:[]
    };
  },
  created() {
    this.fileMsgList();
  },
  methods: {
    async temClick(templateId){
      let data={
        officeType:templateId,//文本文档 w 表格文档 s 演示文档 p
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        taskId:window.sessionStorage.getItem("menuId"),
      }
      let res = await this.$axios.createTemplate(data);
      // console.log(res)
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        // console.log(res.data.data)
        this.fileMsgList()
        this.newMeetdialogVisibleClose()
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    async blanTem(){
      let data={
        officeType:this.command,//文本文档 w 表格文档 s 演示文档 p
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        taskId:window.sessionStorage.getItem("menuId"),
      }
      let res = await this.$axios.createTemplate(data);
      // console.log(res)
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        // console.log(res.data.data)
        this.fileMsgList()
        this.newMeetdialogVisibleClose()
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    newMeetdialogVisibleClose(){
      this.newMeetdialogVisible=false
      this.command='w'
      this.commName=''
    },
    // 复制
    copyLink(){
      let d = document.getElementById("put") //获取需要复制的元素
      d.select() //选中
      document.execCommand("copy") //直接复制
      this.$message.success('复制成功')
    },
    handleLinkType(){
      // console.log(this.sharLink)
      if(this.sharLink=='1'){
        let data={
          name:this.sharRow.name,
          creatorId:this.sharRow.id,
          type:this.sharRow.name.split('.')[1],
          mode:'simple'
        }
        //跳转并携带对象参数
        let encodedObject = encodeURIComponent(JSON.stringify(data));
        this.sharVal=window.location.origin+'/weboffice.html?data='+encodedObject
      }else if(this.sharLink=='2'){
        let data={
          name:this.sharRow.name,
          creatorId:this.sharRow.id,
          type:this.sharRow.name.split('.')[1],
          token: '99999',
          mode:'nomal'
        }
        //跳转并携带对象参数
        let encodedObject = encodeURIComponent(JSON.stringify(data));
        this.sharVal=window.location.origin+'/weboffice.html?data='+encodedObject
      }
      // this.sharVal='http://localhost:8080/weboffice.html'
    },
    shareClick(row){
      this.sharedialogVisible=true
      this.sharRow=row
      let data={
        name:row.name,
        creatorId:row.id,
        type:row.name.split('.')[1],
        mode:'simple'
      }
      //跳转并携带对象参数
      let encodedObject = encodeURIComponent(JSON.stringify(data));
      this.sharVal=window.location.origin+'/weboffice.html?data='+encodedObject
    },
    sharedialogVisibleClose(){
      this.sharRow=[]
      this.sharedialogVisible=false
    },
   async deletFil(row){
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = {
        fileId: row.id,
      };
      let { data: res } = await this.$axios.deleteConferenceFile(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.fileMsgList();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 下载
    async downFile(row){
      let data = _qs.stringify({
        fileId:row.id
      });

      await this.$axios.downloadConferenceFile(data,row.name);
    },
    beforeUpload(file) {
      let extension = file.name.substring(file.name.lastIndexOf('.')+1)
      let size = file.size / 1024 / 1024;
      if(extension !== 'xlsx' && extension !== 'xls') {
        this.$message.warning('只能上传后缀是.xlsx或者.xls的文件')
        return false
      }
      if(size > 10) {
        this.$message.warning('文件大小不得超过10M')
        return false
      }
      return true
    },
    // 文件状态改变
    async handleChange(file, fileList) {
      if (file) {
        // console.log(file)
        this.file = fileList.slice(-3)

        // 可以根据后台接口要求来决定参数的类型
        this.formdata = new FormData()
        this.formdata.append('file', file.raw)
        this.formdata.append('conferenceId', window.sessionStorage.getItem('conferenceId'))
        this.formdata.append('taskId', window.sessionStorage.getItem("menuId"))

        let res = await this.$axios.uploadConferenceFile(this.formdata);
        // console.log(res)
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 200) {
          // console.log(res.data.data)
          this.$message({
            message: '上传成功',
            type: "success",
          });
          this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
          this.fileMsgList()
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      }
    },
    // 文件上传失败
    handleError(err, file, fileList) {
      this.$message.error('文件上传失败')
    },
    open(row){
      // console.log(row)

      let data={
        name:row.name,
        creatorId:row.id,
        type:row.name.split('.')[1],
        token: window.sessionStorage.getItem("userId")
      }

      //跳转并携带对象参数
      let encodedObject = encodeURIComponent(JSON.stringify(data));
      window.open(`/weboffice.html?data=` + encodedObject, '_blank');

    },
    async handleCommand(command) {
      this.newMeetdialogVisible=true
      this.command=command
      if(command=='w'){
        this.commName='新建文字文档'
      }else if(command=='s'){
        this.commName='新建表格文档'
      }else if(command=='p'){
        this.commName='新建演示文档'
      }
      this.fileTemplateList()
    },
    async fileTemplateList() {
      let data = _qs.stringify({
        type:this.command,
        name:'',
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
      });
      let { data: res } = await this.$axios.fileTemplateList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.meetRecore=res.data.records;
        // this.tableData = res.data.records;
        // this.total = res.data.total;
      }
    },
    change(e) {
      this.$forceUpdate(e);
    },
    // 学组管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.fileMsgList();
    },
    async fileMsgList() {
      let data = {
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        taskId:window.sessionStorage.getItem("menuId"),
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
      };
      let { data: res } = await this.$axios.fileMsgList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.data.pageList.records;
        this.total = res.data.data.pageList.total;
        if(res.data.haveAuth=='1'){
          this.haveAuth=false
        }else if(res.data.haveAuth=='0'){
          this.haveAuth=true
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>

/deep/ .el-button.el-button--primary.is-disabled{
  color: #fff!important;
  background-color: #a0cfff!important;
  border-color: #a0cfff!important;
}

.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-textarea__inner {
  width: 220px !important;
}

/deep/ .el-input__inner {
  width: 220px !important;
}

.topBtn{
  margin-bottom: 20px;
  float: right;
  display: flex;
  button{
    width: 120px;
    margin-left: 10px;
  }
}

.sharSty{
  display: flex;
  margin-top: 20px;
  align-items: center;
  .copyStye{
    display: flex;
    margin-left: 10px;
    .el-button{
      margin-left: 10px;
    }
  }
}

.icon-wenziwendang{
  color: rgb(76, 146, 245);
  margin-right: 5px;
}

.icon-a-36-biaogewendang{
  color: rgb(97, 178, 106);
  margin-right: 5px;
}

.icon-yanshiwendang-ppt_pptx{
  color: rgb(241, 108, 65);
  margin-right: 5px;
}

/deep/ .el-upload-list{
  display: none;
}

.bigBor{
  width: 200px;
  text-align: center;
}
.frame{
  width: 150px;
  height: 210px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 200px;
  font-size: 70px;
}

.fraText{
  margin-top: 10px;
  width: 150px;
  text-align: center;
  line-height: 10px;}

.temImag .el-image{
  width: 150px;
  height: 150px;
}

.bomImag{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.bigTem{
  width: 250px;
  text-align: center;
}

.bigBor:hover{
  cursor: pointer;
}

.bigTem:hover{
  cursor: pointer;
}

.temText{
  text-align: center;
}
.mobanDiv {
  font-size: 20px;
  color: #383838;
  font-weight: 800;
}
</style>
